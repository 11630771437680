import {get, post, download} from "./index"

/**
 * 获取商品列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const getCooperateSellerListByPage = data => {
    return post("/seller/getCooperateSellerListByPage", data).then(res => res);
}

/**
 * 保存或者更新销售方
 * @param data
 * @returns {Promise<any>}
 */
export const saveUpdateSeller = data => {
    return post("/seller/saveUpdateSeller", data).then(res => res);
}

/**
 * 批量删除销售方
 * @param data
 * @returns {Promise<unknown>}
 */
export const deleteSellerBatch = data => {
    return post("/seller/deleteSellerBatch", data).then(res => res);
}

/**
 * 获取供应商列表
 * @param data
 * @returns {Promise<unknown>}
 */
export const getRetailerListByPage = data => {
    return post("/retailer/getRetailerListByPage", data).then(res => res);
}

/**
 * 销售方批量导入模版下载
 * @param data
 * @returns {Promise<unknown>}
 */
export const downloadSellerTemplate = data => {
    return download("/seller/downloadSellerTemplate", data).then(res => res);
}

/**
 * 销售方批量导入上传
 * @param data
 * @returns {Promise<unknown>}
 */
export const uploadSellerTemplate = data => {
    return post("/seller/uploadSellerTemplate", data).then(res => res);
}

/**
 * 批量删除销售方
 * @param data
 * @returns {Promise<unknown>}
 */
export const deleteBuyerBatch = data => {
    return post("/retailer/deleteBuyerBatch", data).then(res => res);
}

/**
 * 购买方批量导入模版下载
 * @param data
 * @returns {Promise<unknown>}
 */
export const downloadBuyerTemplate = data => {
    return download("/retailer/downloadBuyerTemplate", data).then(res => res);
}

/**
 * 购买方批量导入上传
 * @param data
 * @returns {Promise<unknown>}
 */
export const uploadBuyerTemplate = data => {
    return post("/retailer/uploadBuyerTemplate", data).then(res => res);
}

/**
 * 保存或者更新购方
 * @param data
 * @returns {Promise<any>}
 */
export const saveUpdateBuyer = data => {
    return post("/retailer/saveUpdateBuyer", data).then(res => res);
}
/**
 * 购买检验值是否合法
 * @param data
 * @returns {Promise<any>}
 */
export const checkCooperateBuyer = data => {
    return post("/retailer/checkCooperateBuyer", data).then(res => res);
}

/**
 * 销方检验值是否合法
 * @param data
 * @returns {Promise<any>}
 */
export const checkCooperateSeller = data => {
    return post("/seller/checkCooperateSeller", data).then(res => res);
}

/**
 * 发起协同
 * @param data
 * @returns {Promise<unknown>}
 */
export const initiate = data => {
    return post("/cooperateRelation/update", data).then(res => res);
}


